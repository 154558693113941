import PropTypes from 'prop-types';
import { find, propEq, pipe, prop, includes, last, split, toLower, map, isEmpty } from 'ramda';
import striptags from 'striptags';
import { truncate } from 'utils/strings';
import Presenter from 'utils/PropTypesPresenter';
import DateTimePresenter from 'presenters/DateTimePresenter';

export const FILE_RESOURCE_TYPE = 'Resource::FileResource';
export const URL_RESOURCE_TYPE = 'Resource::Url';
export const TEXT_RESOURCE_TYPE = 'Resource::Text';
export const URL_DEFAULT_PROTOCOL_STRING = 'https://';
export const APPLICATION_RESOURCE_TYPE = 'Resource::ApplicationResource';

export const PERMISSIONS = {
  publicly: 'Publicly',
  internally: 'Internally',
};

const TEXT = 'text';
const FILE = 'file';
const URL = 'url';
const IMAGE = 'image';
const GDRIVE = 'gdrive';
const APPLICATION = 'application';

const DESCRIPTION_LIMIT = 40;
const resourceTypesMeta = [
  {
    type: FILE,
    resourceType: FILE_RESOURCE_TYPE,
    urlMethod: 'assetUrl',
    urlNameMethod: 'assetFileName',
    availibleExtentions: ['doc', 'docx', 'xls', 'xlsx', 'png', 'jpeg', 'jpg', 'pdf'],
    imageExtentionsWithoutIcon: ['gif'],
  },
  { type: URL, resourceType: URL_RESOURCE_TYPE, urlMethod: 'remoteUrl', urlNameMethod: 'remoteUrl' },
  { type: TEXT, resourceType: TEXT_RESOURCE_TYPE, urlMethod: null, urlNameMethod: null },
  { type: APPLICATION, resourceType: APPLICATION_RESOURCE_TYPE, urlMethod: null, urlNameMethod: null },
];

const resourceMeta = ({ resourceType }) => find(propEq('resourceType', resourceType), resourceTypesMeta);

export default new Presenter(
  {
    id: PropTypes.number,
    name: PropTypes.string,
    description: PropTypes.string,
    text: PropTypes.string,
    assetFileName: PropTypes.string,
    assetUrl: PropTypes.string,
    remoteUrl: PropTypes.string,
    resourceType: PropTypes.string,
    createdAt: PropTypes.string,
    permissions: PropTypes.arrayOf(PropTypes.string),
    updatedAt: PropTypes.string,
  },
  {
    createdAtFormated(resource) {
      return DateTimePresenter.format(this.createdAt(resource), 'L');
    },
    updatedAtFormated(resource) {
      return DateTimePresenter.format(this.updatedAt(resource), 'L');
    },
    url(resource) {
      const { urlMethod } = resourceMeta(resource);
      return prop(urlMethod, resource);
    },
    urlName(resource) {
      const { urlNameMethod } = resourceMeta(resource);
      return prop(urlNameMethod, resource);
    },
    isText(resource) {
      const meta = resourceMeta(resource);
      const { type } = meta;
      return type === TEXT;
    },
    isUrl(resource) {
      const meta = resourceMeta(resource);
      const { type } = meta;
      return type === URL;
    },
    isFile(resource) {
      const meta = resourceMeta(resource);
      const { type } = meta;
      return type === FILE;
    },
    isApplication(resource) {
      const meta = resourceMeta(resource);
      const { type } = meta;
      return type === APPLICATION;
    },
    resourceIcon(resource) {
      const meta = resourceMeta(resource);
      const { type } = meta;
      const url = this.url(resource);

      if (type === URL && includes('drive.google', url)) return GDRIVE;
      if (type === URL || type === TEXT) return type;

      const filename = this.assetFileName(resource);
      const { availibleExtentions, imageExtentionsWithoutIcon } = meta;
      const fileExtention = pipe(split('.'), last, toLower)(filename);

      if (fileExtention && includes(fileExtention, availibleExtentions)) return fileExtention;
      if (fileExtention && includes(fileExtention, imageExtentionsWithoutIcon)) return IMAGE;

      return type;
    },
    type(resource) {
      const { type } = resourceMeta(resource);
      return type;
    },
    stripedDescription(resource) {
      const stripedDescription = striptags(this.description(resource));
      return truncate(stripedDescription, DESCRIPTION_LIMIT);
    },
    permissionLabels(resource) {
      const { permissions } = resource;
      return isEmpty(permissions) ? 'Admins Only' : map(item => PERMISSIONS[item], permissions).join(', ');
    },

    getPublicResources(resources) {
      return resources.filter(r => this.permissions(r).includes(PERMISSIONS.publicly));
    },
  },
);
